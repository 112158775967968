import logo from "./logo.svg";
import "./App.css";
import LandingPage from "./home";

function App() {
  return (
    <>
      <div id="rootalert"></div>

      <LandingPage />
    </>
  );
}

export default App;
